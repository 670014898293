

















/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import VimeoPlayer from "@vimeo/player";

export default Vue.extend({
  props: {
    video: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      player: {} as VimeoPlayer,
      progress: 0
    };
  },
  mounted() {
    //this.setProgress(100);
    this.installPlayer();
  },
  methods: {
    async installPlayer() {
      this.player = new VimeoPlayer("player", {
        id: this.video.vimeoId
      });

      // Video daha önce izlenmiş ise en son kaldığı yere gönder
      this.progress = this.getProgress();

      if (this.progress > 0 && this.progress != 100) {
        const duration = await this.player.getDuration();
        const completedSeconds = (duration * this.progress) / 100;
        await this.player.setCurrentTime(completedSeconds);
      }

      this.player.on("timeupdate", (data: any) => {
        const newProgress = Math.ceil(data.percent * 100);

        // Her %1'lik ilerlemede kaydet
        if (newProgress - this.progress >= 1) {
          this.setProgress(data.percent);
        }
      });

      this.player.on("ended", (data: any) => {
        this.setProgress(data.percent);
      });
    },
    async setProgress(value: number) {
      this.progress = Math.ceil(value * 100);

      const reg = firebase
        .firestore()
        .doc(`businessCourseRegistrations/${this.courseRegistration.id}`);

      const newItem = {
        id: this.video.id as string,
        progress: this.progress,
        type: this.video.type
      };

      // Video tamamlanan dersler dizisinde mi kontrol et
      this.courseRegistration.completedLessonItems.forEach(
        (el: { id: string; progress: number }, i: number) => {
          if (el.id == newItem.id) {
            // Eski kaydı sil
            this.courseRegistration.completedLessonItems.splice(i, 1);
          }
        }
      );

      // Yeni kaydı ekle
      this.courseRegistration.completedLessonItems.push(newItem);

      // Veritabanını güncelle
      await reg.update({
        completedLessonItems: this.courseRegistration.completedLessonItems
      });
    },
    getProgress(): number {
      let returnVal = 0;

      // Video daha önce eklenmiş mi kontrol et ve kalınan yeri bul
      this.courseRegistration.completedLessonItems.forEach(
        (el: { id: string; progress: number }) => {
          if (el.id == this.video.id) {
            returnVal = el.progress;
          }
        }
      );

      return returnVal;
    }
  }
});
