












import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  props: {
    document: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.setProgress(100);
  },
  computed: {
    documentSrc() {
      return this.document.fileUrl;
    }
  },
  methods: {
    async setProgress(value: number) {
      const reg = firebase
        .firestore()
        .doc(`businessCourseRegistrations/${this.courseRegistration.id}`);

      const newItem = {
        id: this.document.id,
        progress: value,
        type: this.document.type
      };

      // Video daha önce eklenmiş mi kontrol et
      const findResult = this.courseRegistration.completedLessonItems.find(
        function(item: any) {
          return item.id === newItem.id;
        }
      );

      if (!findResult) {
        this.courseRegistration.completedLessonItems.push(newItem);
      }

      await reg.update({
        completedLessonItems: this.courseRegistration.completedLessonItems
      });
    }
  }
});
