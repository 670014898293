<template>
  <v-container id="lessonQuiz">
    <v-row no-gutters>
      <v-col class="d-flex justify-center align-center">
        <div class="introBox" v-if="!isStarted">
          <header>
            <h1 class="is-6">Hazır mısınız?</h1>
          </header>
          <div class="mb-6">
            <p>
              Bu modül tamamlama sınavında başarılı olabilmek için tüm soruları
              doğru yanıtlamanız gerekmektedir. Başarısız olmanız durumunda
              tekrar deneyebilirsiniz.
            </p>
            <p>Başarılar!</p>
          </div>
          <div class="text-right">
            <v-btn color="primary" rounded @click="start">Başla</v-btn>
          </div>
        </div>

        <!--questionBox-->
        <div class="questionBox" v-if="isStarted">
          <!-- transition -->
          <transition
            :duration="{ enter: 500, leave: 300 }"
            enter-active-class="animated zoomIn"
            leave-active-class="animated zoomOut"
            mode="out-in"
          >
            <div>
              <!--qusetionContainer-->
              <div
                class="questionContainer"
                v-if="questionIndex < questions.length"
                v-bind:key="questionIndex"
              >
                <header v-if="showHeader">
                  <!--progress-->
                  <div class="progressContainer">
                    <progress
                      class="progress is-info is-small"
                      :value="(questionIndex / questions.length) * 100"
                      max="100"
                      >{{ (questionIndex / questions.length) * 100 }}%</progress
                    >
                    <p>
                      {{ (questionIndex / questions.length) * 100 }}%
                      tamamlandı.
                    </p>
                  </div>
                  <!--/progress-->
                </header>

                <!-- questionTitle -->
                <div class="titleContainer">
                  <span class="questionIndex primary--text"
                    >Soru {{ questionIndex + 1 }} / {{ questions.length }}</span
                  >
                  <!-- {{ clearHtmlTags(questions[questionIndex].text) }} -->
                  <div v-html="questions[questionIndex].text" />
                </div>

                <!-- quizOptions -->
                <div class="optionContainer">
                  <div
                    class="option"
                    v-for="(response, index) in questions[questionIndex]
                      .options"
                    @click="selectOption(index)"
                    :class="{
                      'is-selected': userResponses[questionIndex] == index
                    }"
                    :key="index"
                  >
                    <span class="optionIndex" v-if="false">{{
                      index | charIndex
                    }}</span>

                    {{ clearHtmlTags(response.text) }}
                  </div>
                </div>

                <!--quizFooter: navigation and progress-->
                <footer class="questionFooter">
                  <!--pagination-->
                  <nav
                    class="pagination"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <!-- back button -->
                    <v-btn
                      rounded
                      v-on:click="prev()"
                      :disabled="questionIndex < 1"
                      >Geri</v-btn
                    >

                    <!-- next button -->
                    <v-btn
                      rounded
                      :class="
                        userResponses[questionIndex] == null
                          ? ''
                          : 'is-active primary'
                      "
                      v-on:click="next()"
                      v-if="questionIndex + 1 != questions.length"
                      :disabled="questionIndex >= questions.length"
                      >{{
                        userResponses[questionIndex] == null ? "Geç" : "İleri"
                      }}</v-btn
                    >

                    <!-- finish button -->
                    <v-btn
                      v-if="questionIndex + 1 == questions.length"
                      rounded
                      :class="
                        userResponses[questionIndex] == null
                          ? ''
                          : 'is-active primary'
                      "
                      v-on:click="finish()"
                      :disabled="questionIndex >= questions.length"
                      >Bitir</v-btn
                    >
                  </nav>
                  <!--/pagination-->
                </footer>
                <!--/quizFooter-->
              </div>
              <!--/questionContainer-->

              <!--quizCompletedResult-->
              <div
                v-if="questionIndex >= questions.length"
                v-bind:key="questionIndex"
                class="quizCompleted has-text-centered"
              >
                <!-- quizCompletedIcon: Achievement Icon -->
                <span class="icon">
                  <i
                    class="fa"
                    :class="
                      status() == 'passed'
                        ? 'fa-check-circle-o is-active'
                        : 'fa-times-circle'
                    "
                  ></i>
                </span>

                <!--resultTitleBlock-->
                <h2>
                  {{
                    status() == "passed"
                      ? "Tebrikler! Modülü başarıyla tamamladınız."
                      : "Maalesef başarısız oldunuz."
                  }}
                </h2>
                <p class="subtitle mt-3">
                  Puan: {{ score() }} / {{ questions.length }}
                </p>
                <br />
                <v-btn
                  rounded
                  @click="reset()"
                  v-if="status() == 'failed'"
                  class="mx-3"
                >
                  Tekrar Dene
                  <i class="fa fa-refresh ml-3"></i>
                </v-btn>
                <v-btn rounded @click="close" class="mx-3">
                  Kapat
                </v-btn>
                <!--/resultTitleBlock-->
              </div>
              <!--/quizCompetedResult-->
            </div>
          </transition>
        </div>
        <!--/questionBox-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  props: {
    course: {
      type: Object,
      required: true
    },
    lesson: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    questions: [
      {
        text: "Soru",
        options: [
          { text: "Seçenek" },
          { text: "Seçenek", correct: true },
          { text: "Seçenek" },
          { text: "Seçenek" },
          { text: "Seçenek" }
        ]
      }
    ],
    questionIndex: 0,
    userResponses: [],
    isActive: false,
    isStarted: false,
    showHeader: false,
    finishTimestamp: null
  }),
  computed: {
    user: function() {
      return this.$store.getters["auth/user"];
    }
  },
  filters: {
    charIndex: function(i) {
      return String.fromCharCode(97 + i);
    }
  },
  async mounted() {
    // this.restart();
    // this.getQuestions();
    await this.reset();
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    selectOption: function(index) {
      this.$set(this.userResponses, this.questionIndex, index);
    },
    next: function() {
      if (this.questionIndex < this.questions.length) this.questionIndex++;
    },
    prev: function() {
      if (this.questions.length > 0) this.questionIndex--;
    },
    // Return "true" count in userResponses
    score: function() {
      let score = 0;
      for (let i = 0; i < this.userResponses.length; i++) {
        if (
          typeof this.questions[i].options[this.userResponses[i]] !==
            "undefined" &&
          this.questions[i].options[this.userResponses[i]].correct
        ) {
          score = score + 1;
        }
      }
      return score;

      //return this.userResponses.filter(function(val) { return val }).length;
    },
    status: function() {
      let status = "failed";
      if (this.score() == this.questions.length) {
        status = "passed";
      }
      return status;
    },
    start: async function() {
      this.isStarted = true;
    },
    getQuestions: async function() {
      this.questions = [];

      const dbRef = firebase.firestore().collection("questions");

      // Get all IDs
      const idQs = await dbRef
        .where("lessonId", "==", this.lesson.id)
        .where("disabled", "==", false)
        .get();

      const idArray = idQs.docs.map(d => d.id);

      const randomQ = this.shuffleArray(idArray).splice(0, 5);

      const questionsQs = await dbRef
        .where(firebase.firestore.FieldPath.documentId(), "in", randomQ)
        .get();

      questionsQs.forEach(qDoc => {
        const q = qDoc.data();

        const newQ = {
          id: qDoc.id,
          text: q.q,
          options: [
            {
              text: q.a,
              correct: q.correct.toLowerCase() == "a" ? true : false
            },
            {
              text: q.b,
              correct: q.correct.toLowerCase() == "b" ? true : false
            },
            {
              text: q.c,
              correct: q.correct.toLowerCase() == "c" ? true : false
            },
            {
              text: q.d,
              correct: q.correct.toLowerCase() == "d" ? true : false
            },
            {
              text: q.e,
              correct: q.correct.toLowerCase() == "e" ? true : false
            }
          ]
        };
        newQ.options = this.shuffleArray(newQ.options);
        this.questions.push(newQ);
      });

      this.userResponses = Array(this.questions.length).fill(null);
    },
    reset: async function() {
      await this.getQuestions();

      this.questionIndex = 0;
      this.userResponses = Array(this.questions.length).fill(null);
    },
    clearHtmlTags: function(html) {
      const tmp = document.createElement("div");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    shuffleArray: function(a) {
      let ctr = a.length,
        temp,
        index;

      while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = a[ctr];
        a[ctr] = a[index];
        a[index] = temp;
      }
      return a;
    },
    finish: async function() {
      this.next();

      // Sınav sonucunu veritabanına kaydet. Öğrenci başarılı ise modülü tamamlandı olarak işaretle.
      this.finishTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      await firebase
        .firestore()
        .collection("quizzes")
        .add({
          uid: this.user.id,
          courseId: this.course.id,
          lessonId: this.lesson.id,
          dateCreated: this.finishTimestamp,
          score: this.score(),
          status: this.status(),
          questions: this.questions,
          userResponses: this.userResponses
        });

      if (this.status() == "passed") {
        this.calculateAndSaveCourseProgress();
      }
    },
    calculateAndSaveCourseProgress: async function() {
      // Tamamlanan ders dizisini güncelle
      const reg = firebase
        .firestore()
        .doc(`businessCourseRegistrations/${this.courseRegistration.id}`);

      this.courseRegistration.completedLessons.push(this.lesson.id);

      const completedLessonCount = this.courseRegistration.completedLessons
        .length;

      // Toplam modül sayısını al
      const lessonQs = await firebase
        .firestore()
        .collection("lessons")
        .where("courseId", "==", this.course.id)
        .get();

      const lessonCount = lessonQs.docs.length;

      // İlerleme yüzdesini hesapla
      this.courseRegistration.progress = Math.round(
        (100 * completedLessonCount) / lessonCount
      );
      this.courseRegistration.progress =
        this.courseRegistration.progress > 100
          ? 100
          : this.courseRegistration.progress;

      // Veritabanını güncelle
      await reg.update({
        progress: this.courseRegistration.progress,
        completedLessons: this.courseRegistration.completedLessons,
        dateGraduated: this.finishTimestamp
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#lessonQuiz {
  $trans_duration: 0.3s;
  $primary_color: $color1;

  .button {
    transition: $trans_duration;
  }
  .title,
  .subtitle {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
  }
  .animated {
    transition-duration: $trans_duration/2;
  }

  .container {
    margin: 0 0.5rem;
  }

  .introBox {
    max-width: 30rem;
    width: 30rem;
    background: #fafafa;
    padding: 1.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .questionBox {
    max-width: 30rem;
    width: 30rem;
    min-height: 30rem;

    background: #fafafa;
    position: relative;
    display: flex;

    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    header {
      background: rgba(0, 0, 0, 0.025);
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      h1 {
        font-weight: bold;
        margin-bottom: 1rem !important;
      }
      .progressContainer {
        width: 60%;
        margin: 0 auto;
        > progress {
          margin: 0;
          border-radius: 5rem;
          overflow: hidden;
          border: none;

          color: $primary_color;
          &::-moz-progress-bar {
            background: $primary_color;
          }
          &::-webkit-progress-value {
            background: $primary_color;
          }
        }
        > p {
          margin: 0;
          margin-top: 0.5rem;
        }
      }
    }
    .titleContainer {
      // text-align: center;
      margin: 0;
      padding: 1.5rem;
      font-size: 1.2rem;
      overflow: hidden;

      :last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        font-size: 1rem;
        color: $primary_color;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .quizForm {
      display: block;
      white-space: normal;

      height: 100%;
      width: 100%;

      .quizFormContainer {
        height: 100%;
        margin: 15px 18px;

        .field-label {
          text-align: left;
          margin-bottom: 0.5rem;
        }
      }
    }
    .quizCompleted {
      width: 100%;
      padding: 1rem;
      text-align: center;

      > .icon {
        color: #ff5252;
        font-size: 5rem;

        .is-active {
          color: #00e676;
        }
      }
    }
    .questionContainer {
      white-space: normal;

      height: 100%;
      width: 100%;

      .optionContainer {
        margin-top: 12px;
        flex-grow: 1;

        .option {
          border-radius: 10px;
          padding: 9px 18px;
          margin: 0 18px;
          margin-bottom: 12px;
          transition: $trans_duration;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.85);
          border: transparent 1px solid;
          position: relative;

          .optionIndex {
            display: block;
            position: absolute;
            top: -5px;
            left: -5px;
            background-color: $primary_color;
            color: #fff;
            border-radius: 50%;
            font-size: 0.8rem;
            width: 20px;
            height: 20px;
            overflow: hidden;
            text-align: center;
            line-height: 150%;
          }

          &.is-selected {
            border-color: $primary_color;
            background-color: white;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
          &:active {
            transform: scaleX(0.9);
          }
        }
      }

      .questionFooter {
        background: rgba(0, 0, 0, 0.025);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        align-self: flex-end;

        .pagination {
          //padding: 10px 15px;
          margin: 15px 25px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
  }
  .button {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5rem;
    margin: 0 0.25rem;

    transition: 0.3s;

    &:hover {
      cursor: pointer;
      background: #eceff1;
      border-color: rgba(0, 0, 0, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }

  @media screen and (min-width: 769px) {
    .questionBox {
      align-items: center;
      justify-content: center;

      .questionContainer {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      height: auto !important;
      border-radius: 6px 6px 0px 0px;
    }
  }
}
</style>
